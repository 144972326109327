import axios from 'axios'

let baseUrl = 'https://us-central1-adminpanel-a5b48.cloudfunctions.net'
let baseUrlTest = 'http://localhost:5001/adminpanel-a5b48/us-central1'
let url = baseUrl

function loadProducts(product) {
  let params = product ? {
    params: {
      product: product
    }
  } : null
  return axios.get(url + '/getShopProducts2', params)
}

function loadPickupLocations() {
  return axios.get(url + '/getPickupLocations')
}

function loadPickupLocationsWithMode(mode) {
  return axios.get(url + '/getPickupLocations', {
    params: {
      mode: mode
    }
  })
}

function loadPickupMessage(projectId, messageId) {
  return axios.post(url + '/getPickupMessage', {
    projectId: projectId,
    messageId: messageId
  })
}

function updatePickupMessage(projectId, messageId, data) {
  return axios.post(url + '/updatePickupMessage', {
    projectId: projectId,
    messageId: messageId,
    data: data
  })
}

function updatePickupPrefs(pickupId, data) {
  return axios.post(url + '/updatePickupLocationPrefs', {
    pickupLocationId: pickupId,
    data: data
  })
}

function verifyMobileNumber(projectId, messageId) {
  return axios.post(url + '/verifyMobileNumber', {
    projectId: projectId,
    messageId: messageId
  })
}

function loadDominoColors() {
  return axios.get(url + '/getDominoColors')
}

function pushOrder(order) {
  return axios.post(url + '/pushOrder', order)
}

function requestCourse(enquiry) {
  return axios.post(url + '/requestCourse', enquiry)
}

function requestRent(enquiry) {
  return axios.post(url + '/requestRent', enquiry)
}

function submitRent(rent) {
  return axios.post(url + '/submitRent', rent)
}

function submitTelefonBeratung(termin) {
  termin.source = 'www.swissdominoeffect.com'
  return axios.post(url + '/telephone', termin)
}

function loadBillDocument(orderId) {
  return axios.get(url + "/document", {
    params: {
      type: 'rechnung',
      data: orderId
    }
  })
}

function loadPageNotifications(page_id) {
  return axios.get(url + "/pageNotifications", {
    params: {
      page_id: page_id
    }
  })
}

function contact(request) {
  request.source = 'www.swissdominoeffect.com'
  return axios.post(url + "/contact", request)
}

function accessPortal(code) {
  return axios.get(url + "/portalAccess", {
    params: {
      code: code
    }
  })
}

function namePortal(code) {
  return axios.get(url + "/portalName", {
    params: {
      code: code
    }
  })
}

function addToShoppingCart(id, element) {
  return axios.get(url + "/addToShoppingCart", {
    params: {
      id: id,
      element: element
    }
  })
}

function updateShoppingCart(id, product_id, product_count) {
  return axios.get(url + "/updateShoppingCart", {
    params: {
      id: id,
      product_id: product_id,
      product_count: product_count
    }
  })
}

function loadShoppingCart(id, calculate_delivery) {
  return axios.get(url + "/loadShoppingCart", {
    params: {
      id: id,
      delivery: calculate_delivery
    }
  })
}

function deleteShoppingCart(id, product_id) {
  return axios.get(url + "/deleteShoppingCart", {
    params: {
      id: id,
      product_id: product_id
    }
  })
}

function payShoppingCart(id, meta) {
  return axios.post(url + "/payShoppingCart", {
    id: id,
    meta: meta
  })
}

function voucherShoppingCart(id, voucher) {
  return axios.post(url + "/voucherShoppingCart", {
    id: id,
    voucher: voucher
  })
}

export {
  loadProducts,
  loadDominoColors,
  pushOrder,
  requestRent,
  loadBillDocument,
  loadPageNotifications,
  contact,
  submitRent,
  submitTelefonBeratung,
  accessPortal,
  namePortal,
  requestCourse,
  addToShoppingCart,
  loadShoppingCart,
  updateShoppingCart,
  deleteShoppingCart,
  payShoppingCart,
  voucherShoppingCart,
  loadPickupLocations,
  loadPickupLocationsWithMode,
  loadPickupMessage,
  updatePickupMessage,
  verifyMobileNumber,
  updatePickupPrefs
}
